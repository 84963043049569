import { render, staticRenderFns } from "./ImgFallback2.vue?vue&type=template&id=699728cb&"
import script from "./ImgFallback2.vue?vue&type=script&lang=js&"
export * from "./ImgFallback2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('699728cb')) {
      api.createRecord('699728cb', component.options)
    } else {
      api.reload('699728cb', component.options)
    }
    module.hot.accept("./ImgFallback2.vue?vue&type=template&id=699728cb&", function () {
      api.rerender('699728cb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8lRv16BLK1hIU/JS/comp/ImgFallback2.vue"
export default component.exports